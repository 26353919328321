import React, { useCallback, useEffect, useState } from "react";
import "./Group.css";
import PopupModal from "./PopupModal";

function Groups({ list, generate, groupsize }) {
  const [randomList, setRandomList] = useState([]);
  const [randomGroups, setRandomGroups] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const generateGroups = useCallback(() => {
    const result = [];
    for (let i = groupsize; i > 0; i--) {
      result.push(randomList.splice(0, Math.ceil(randomList.length / i)));
    }
    setRandomGroups(result);
  }, [randomList, groupsize]);

  useEffect(() => {
    setRandomList(shuffleArray([...list]));
  }, [generate, list]);

  useEffect(() => {
    generateGroups();
  }, [randomList, generateGroups]);


  const popupClick = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowEmailInput(false); 
  };

  const handleYesClick = () => {
    setShowEmailInput(true);
  };

  const handleNoClick = () => {
    setShowPopup(false);
  };




  return (
    <div className="GroupList">
      {randomGroups.length !== 0 &&
        randomGroups.map((group, i) => {
          if (group.length !== 0) {
            return (
              <div key={i} className="Group">
                <p>Group {i + 1}</p>
                <ul>
                  {group.map((ele, j) => {
                    return <li key={j}>{ele}</li>;
                  })}
                </ul>
              </div>
            );
          }
          return <></>;
        })}
      {randomGroups.length !== 0 && (
        <div className="SaveButtonContainer">
          <button
            className="btn"
            style={{
              borderRadius: "3px",
              backgroundColor: "rgb(252, 169, 3)",
              color: "black"
            }}
            onClick={popupClick}
          >
            Save the Team
          </button>
          {showPopup && (
        <PopupModal
          onClose={closePopup}
          onYesClick={handleYesClick}
          onNoClick={handleNoClick}
          showEmailInput={showEmailInput}
        />
      )}
        </div>
      )}
    </div>
  );
}

export default Groups;
