import React from "react";
import "./List.css";

function List({ list, removeMember }) {
  return (
    <div className="List">
      {list.map((ele, i) => {
        return (
          <div key={i} className="ListItem">
            <p>{ele}</p>
            <button className="rm-btn" onClick={() => removeMember(i)}>
              Remove
            </button>
          </div>
        );
      })}
    </div>
  );
}

export default List;
