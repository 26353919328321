import React, { useState } from "react";
import "./Input.css";


function Input({ addMember }) {
  const [value, setValue] = useState("");
  const setMember = (e) => {
    e.preventDefault();
    if (value !== "") {
      addMember(value);
      setValue("");
    }
  };
  return (
    <div className="Input">
      <form>
        <input
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          placeholder="Enter team members here..."
        />
       <button
  type="submit"
  style={{
    borderRadius: "3px",
    backgroundColor: "rgb(252, 169, 3)",
    color: "black"
  }}
  onClick={(e) => setMember(e)}
>
  ADD
</button>

      </form>
    </div>
  );
}

export default Input;
