import React from "react";
import "./GroupInput.css";
function GroupInput({
  generate,
  setGenerate,
  groupsize,
  setGroupSize,
  list_size,
}) {
  const generateGroup = () => {
    console.log(groupsize);
    console.log(list_size);
    if (groupsize >= 1 && groupsize <= list_size) {
      setGenerate(!generate);
    } else {
      alert("Invalid Group Size");
    }
  };
  return (
    <div className="GroupInput">
      <label htmlFor="g-input" style={{color:"white"}}>Number of groups:</label>
      <input
        type="number"
        id="g-input"
        value={groupsize}
        onChange={(e) =>
          e.target.value < 0 ? setGroupSize(1) : setGroupSize(e.target.value)
        }
      />
    <button onClick={() => generateGroup()} style={{ color: "black", backgroundColor: "rgb(252, 169, 3)", height: "35px" }}>Generate</button>

    </div>
  );
}

export default GroupInput;
