import React, { useState, useEffect } from "react";
import List from "./List";
import GroupInput from "./GroupInput";
import Groups from "./Groups";
import Input from "./Input";
import PopupModal from "./PopupModal";
import "./TeamGenerator.css";

function TeamGenerator() {
  const [list, setList] = useState([]);
  const [generate, setGenerate] = useState(false);
  const [groupsize, setGroupSize] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);

  const addMember = (member) => {
    setList([...list, member]);
  };

  const removeMember = (index) => {
    setList(list.filter((ele, i) => i !== index));
  };

  const handleClickHere = (e) => {
    e.preventDefault();
    setShowPopup(true);
  };

  const closePopup = () => {
    setShowPopup(false);
    setShowEmailInput(false);
  };

  const handleYesClick = () => {
    setShowEmailInput(true);
  };

  const handleNoClick = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (list.length === 0) {
      setGenerate(false);
      setGroupSize(1);
    }
  }, [list]);

  return (
    <>
      <h3 className="h2 fw-bold my-4 text-center">Random Team Generator</h3>
      {/* <h5 className="h5 mb-3 mt-4 text-center">
        Wanna Make Customisable Teams?{" "}
        <span onClick={handleClickHere} style={{ cursor: "pointer", color: "blue" }}>
          Click Here
        </span>
      </h5> */}

      <div className="content-container">
        <div className="wrapper-container">
          <div className="row">
            <div className="col-md-6">
              <Input addMember={addMember} />
              {list.length !== 0 && (
                <>
                  <List list={list} removeMember={removeMember} />
                  <GroupInput
                    generate={generate}
                    setGenerate={setGenerate}
                    groupsize={groupsize}
                    setGroupSize={setGroupSize}
                    list_size={list.length}
                  />
                </>
              )}
            </div>
            <div className="col-md-6">
              {list.length !== 0 && (
                <Groups list={list} generate={generate} groupsize={groupsize} />
              )}
            </div>
          </div>
        </div>
      </div>

      {showPopup && (
        <PopupModal
          onClose={closePopup}
          onYesClick={handleYesClick}
          onNoClick={handleNoClick}
          showEmailInput={showEmailInput}
        />
      )}
    </>
  );
}

export default TeamGenerator;
