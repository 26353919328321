import React from "react";
import Navbar from "../components/Navbar";
import TeamGenerator from "../components/TeamGenerator";
import Footer from "../components/Footer";

function Home() {
  return (
    <>
      <Navbar />
      <TeamGenerator />
      <Footer />
    </>
  );
}

export default Home;
